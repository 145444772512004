import { useMemo } from 'react';
import { useProfileV2 } from '../Profile';
import { getCountry, type SupportedCountries } from './useCountries';

type SupportedCompany = {
  hasAccess: boolean;
  countries: SupportedCountries;
};

export type SupportedCompanies = {
  recycling: SupportedCompany;
  confidential: SupportedCompany;
};

const getCompany = (dwKey: string) => {
  if (isRecycling(dwKey)) {
    return { recycling: true, confidential: false };
  } else if (isConfidential(dwKey)) {
    return { confidential: true, recycling: false };
  }
};

const getAllCompanies = () => {
  return {
    recycling: {
      hasAccess: true,
      countries: {
        sweden: true,
        denmark: true,
        norway: true,
        finland: true,
        poland: true,
      },
    },
    confidential: {
      hasAccess: true,
      countries: {
        sweden: true,
        denmark: true,
        norway: true,
        finland: true,
        poland: true,
      },
    },
  } as SupportedCompanies;
};

export const useCompanies = () => {
  const { profile } = useProfileV2();

  const supportedCompanies: SupportedCompanies = useMemo(() => {
    if (!profile) {
      return getAllCompanies();
    }
    const perms = profile.permissions;

    if (!perms?.businessPartners) {
      return getAllCompanies();
    }

    const countries = perms.businessPartners.reduce<SupportedCompanies>(
      (acc, bp) => {
        const company = getCompany(bp.id);
        const country = getCountry(bp.id);

        if (!company) {
          return acc;
        }

        if (company.recycling) {
          acc.recycling.countries = { ...acc.recycling.countries, ...country };
          acc.recycling.hasAccess = true;
        }
        if (company.confidential) {
          acc.confidential.countries = { ...acc.confidential.countries, ...country };
          acc.confidential.hasAccess = true;
        }

        return acc;
      },
      {
        recycling: {
          hasAccess: false,
          countries: {},
        },

        confidential: {
          hasAccess: false,
          countries: {},
        },
      },
    );

    return Object.keys(countries).length > 0 ? countries : getAllCompanies();
  }, [profile]);

  return { supportedCompanies };
};

export const isRecycling = (dwKey: string) =>
  dwKey.startsWith('104') ||
  dwKey.startsWith('250') ||
  dwKey.startsWith('555') ||
  dwKey.startsWith('505') ||
  dwKey.startsWith('105');

export const isConfidential = (dwKey: string) =>
  dwKey.startsWith('900') || dwKey.startsWith('901') || dwKey.startsWith('902');

import { useMemo } from 'react';
import { useFlag } from '..';
import { useCompanies } from './useCompanies';

export const useOPV2 = () => {
  const { supportedCompanies } = useCompanies();
  const useOrderPickupV2 = useFlag('use-order-pickup-v2');
  const useOrderPickupV2Denmark = useFlag('use-order-pickup-v2-denmark');
  const useOrderPickupV2Norway = useFlag('use-order-pickup-v2-norway');
  const useOrderPickupV2Finland = useFlag('use-order-pickup-v2-finland');

  const isOpV2 = useMemo(() => {
    if (
      !!supportedCompanies.recycling.countries.sweden ||
      supportedCompanies.confidential.hasAccess
    ) {
      return useOrderPickupV2;
    }
    if (supportedCompanies.recycling.countries.denmark) {
      return useOrderPickupV2Denmark;
    }
    if (supportedCompanies.recycling.countries.norway) {
      return useOrderPickupV2Norway;
    }
    if (supportedCompanies.recycling.countries.finland) {
      return useOrderPickupV2Finland;
    }

    return false;
  }, [
    useOrderPickupV2,
    useOrderPickupV2Denmark,
    useOrderPickupV2Norway,
    useOrderPickupV2Finland,
    supportedCompanies,
  ]);

  return isOpV2;
};

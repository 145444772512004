import { type AppState, Auth0Provider, type Auth0ProviderOptions } from '@auth0/auth0-react';
import { type ReactNode } from 'react';
import { ProfileProvider } from '../Profile';
import { ServiceProvider } from '../Services/ServiceProvider';
import { getAuth0Config } from '../utils/environment';
import { LocationsProvider } from '../Locations/LocationsProvider';

type AuthProviderProps = {
  domain?: string;
  clientId?: string;
} & Omit<Auth0ProviderOptions, 'domain' | 'clientId' | 'audience' | 'redirectUrl'>;
/**
 * Wrap your application with this component to get access to the profile and use Auth0-authentication.
 * If cachelocation is localstorage or undefined, will cache accessToken in localStorage for 1 minute.
 * After the minute is passed, the framework will automatically get new instance of token. This is done to ensure that the
 * token is still valid (not expired and user has not signed out from other locations).
 * @param param0 { domain, clientId } If not specified, the domain and clientId will be taken from the environment.
 * @returns
 */
export const AuthProvider = ({ children, domain, clientId, ...rest }: AuthProviderProps) => {
  const auth0Config = getAuth0Config();
  const audience = 'https://user.stenarecycling.cloud/api';

  const getLangVariable = () => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');

    for (const item of vars) {
      const pair = item.split('=');

      if (decodeURIComponent(pair[0]) === 'lang') {
        return decodeURIComponent(pair[1]);
      }
    }

    return;
  };

  const langFromQuery = getLangVariable();

  return (
    <Auth0Provider
      domain={domain ?? auth0Config.domain}
      clientId={clientId ?? auth0Config.clientId}
      redirectUri={window.location.origin}
      audience={audience}
      langFromQuery={langFromQuery}
      {...rest}
    >
      <ProfileProvider>
        <LocationsProvider>
          <ServiceProvider>{children}</ServiceProvider>
        </LocationsProvider>
      </ProfileProvider>
    </Auth0Provider>
  );
};

type Auth0ProviderWithHistoryProps = {
  children: ReactNode;
};

export const Auth0ProviderWithHistory = ({ children }: Auth0ProviderWithHistoryProps) => {
  const onRedirectCallback = (appState: AppState | undefined) => {
    let url = appState?.returnTo ?? window.location.pathname;

    if (url.includes('?code')) {
      //Remove query parameters from the url
      url = url.split('?')[0];
    }
    window.location.replace(url);
  };

  return (
    <AuthProvider
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </AuthProvider>
  );
};

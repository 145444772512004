import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../../../themes';
import { hoverCheckboxStyle } from '../../styles';

export const ListWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ListItemWrapper = styled.li<{ isSelected: boolean; bottomBorder?: boolean }>`
  ${typography.body.large};
  display: flex;
  flex-flow: row;
  gap: ${spacing.xsmall};
  padding: ${spacing.xmsmall};
  background-color: ${({ isSelected }) => (isSelected ? colors.monochrome.grey10 : 'transparent')};

  ${({ bottomBorder }) => bottomBorder && `border-bottom: 1px solid ${colors.monochrome.grey20};`}

  &:hover {
    background-color: ${colors.monochrome.grey10};
    cursor: pointer;
  }

  ${hoverCheckboxStyle}
`;

export const Divider = styled.hr`
  border: 0;
  height: 1px;
  background-color: ${colors.monochrome.grey20};
  margin: 0;
`;

export const GroupLabel = styled.div`
  ${typography.body.largeBold};
  padding-top: ${spacing.xsmall};
  padding-bottom: ${spacing.tiny};
  padding-left: ${spacing.xsmall};
  padding-right: ${spacing.xsmall};
`;

export const Description = styled.div`
  ${typography.body.medium};
  color: ${colors.primary.grey};
`;

export const SimpleListItemWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

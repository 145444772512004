import { IconWrapper } from './style';
import type { IconProps } from '../../types';

export const Menu = ({ color, rotation, width = '24px', height = '24px', cursor }: IconProps) => (
  <IconWrapper width={width} height={height} cursor={cursor}>
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${rotation ? rotation : 0})`}
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        d="M11 26.5625H29"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.63636"
        strokeLinecap="round"
        transform="translate(-9, -8.5)"
      ></path>
      <path
        d="M11 19.8125H29"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.63636"
        strokeLinecap="round"
        transform="translate(-9, -8.5)"
      ></path>
      <path
        d="M11 13.0625H29"
        stroke={color ? color : 'currentColor'}
        strokeWidth="1.63636"
        strokeLinecap="round"
        transform="translate(-9, -8.5)"
      ></path>
    </svg>
  </IconWrapper>
);

import styled from '@emotion/styled';
import { colors, spacing, typography } from 'component-library';
import { overlayColor } from 'component-library/src/components/helpers/styleHelpers';
import { maxmq } from '../../assets/styles/breakpoints';

export const MobileMenuWrapper = styled.div`
  display: none;
  margin-right: ${spacing.xxsmall};
  ${maxmq[2]} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const MenuButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${spacing.medium};
  height: ${spacing.medium};
`;

export const MenuButton = styled.button`
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
`;

export const MobileMenuFoldout = styled.div`
  position: fixed;
  z-index: 100;
  top: ${spacing.xxlarge};
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${overlayColor};
`;

export const MobileMenuFoldoutList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${colors.base.white};
`;

export const MobileMenuFoldoutListItem = styled.li`
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.monochrome.grey20};
  display: flex;
  align-items: center;
`;

export const MobileMenuLink = styled.a<{ isActive: boolean }>`
  display: block;
  padding: ${spacing.small};
  width: 100%;
  ${typography.other.burgerMenuLabel};
  color: ${colors.base.black};
  text-decoration: none;
`;

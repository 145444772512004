import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../themes/stena-recycling';
import { boxShadow, clipPathTop } from '../../helpers/styleHelpers';
import { inputBorderStyle } from '../../helpers/inputHelpers';

const dropDownMaxHeight = 240;

export const DropdownWrapper = styled.div`
  position: relative;
  flex-direction: column;
`;

export const DropdownHeaderWrapper = styled.button<{
  error: boolean;
  isPlaceholder: boolean;
  isOpen: boolean;
}>`
  ${({ error }) => inputBorderStyle(!!error)}
  font-family: inherit;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  background-color: ${colors.base.white};
  margin: 0;
  cursor: pointer;
  color: ${({ isPlaceholder }) => (isPlaceholder ? colors.monochrome.grey60 : colors.base.black)};
  height: ${spacing.mediumHigh};
  width: 100%;

  &:disabled {
    background-color: ${colors.monochrome.grey10};
    color: ${colors.monochrome.grey60};
    border: 1px solid ${colors.primary.grey};
    cursor: not-allowed;
  }

  &:hover {
    border: ${({ isOpen }) => (isOpen ? '2px' : '1px')} solid
      ${({ error }) => (error ? colors.alerts.error : colors.primary.grey)};
  }

  ${({ isOpen, error }) => {
    if (isOpen) {
      return css`
        border: 2px solid ${error ? colors.alerts.error : colors.base.black};
        padding: calc(${spacing.xmsmall} - 1px) calc(${spacing.xmsmall} - 1px) ${spacing.xxsmall}
          calc(${spacing.xmsmall} - 1px);
      `;
    }

    return;
  }}
`;

export const IconWrapper = styled.div`
  margin-right: ${spacing.xmsmall};
`;

export const DropdownHeader = styled.div`
  display: flex;
  font-family: inherit;
  ${typography.other.inputValue}
`;

export const DropdownIcon = styled.div<{ open: boolean }>`
  position: absolute;
  height: ${spacing.xsmall};
  width: ${spacing.xsmall};
  color: ${colors.base.black};
  right: 0;
  bottom: 0;
  padding: 15px;
  box-sizing: content-box;
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const DropdownList = styled.ul<{ useScroll: boolean; isOpen: boolean; hasLabel: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  top: ${({ hasLabel }) => (hasLabel ? '72px' : '48px')};
  width: 100%;
  z-index: 1;
  list-style: none;
  margin: ${spacing.tiny} 0 0 0;
  padding: 0;
  clip-path: ${clipPathTop};
  box-shadow: ${boxShadow};
  ${({ useScroll }) => useScroll && `max-height: ${dropDownMaxHeight}px; overflow-y: auto;`}
  box-sizing: border-box;
  border: 1px solid ${colors.monochrome.grey20};
  border-radius: 4px;
  overflow: hidden;
`;

export const DropdownListItem = styled.li`
  font-family: inherit;
  display: flex;
  flex-direction: column;
  color: ${colors.base.black};
  background-color: ${colors.base.white};
  width: 100%;
  box-sizing: border-box;
  padding: ${spacing.xmsmall} ${spacing.xsmall};
  cursor: pointer;
  ${typography.other.inputValue}

  &:hover {
    background-color: ${colors.monochrome.grey10};
  }
`;

export const SubLabelWrapper = styled.div`
  color: ${colors.primary.grey};
`;

export const DropdownLabel = styled.span`
  display: block;
  ${typography.other.inputLabel}
  margin-bottom: ${spacing.tiny};
  color: ${colors.primary.grey};
`;

export const DropdownError = styled.span`
  ${typography.body.medium}
  letter-spacing: 0.5px;
  color: ${colors.alerts.error};
`;

export const InfoMessage = styled.span<{ severity?: 'info' | 'success' | 'error' | 'warning' }>`
  display: flex;
  align-items: center;
  height: 38px;
  box-sizing: border-box;
  margin-top: ${spacing.xxsmall};
  padding: ${spacing.xxsmall} ${spacing.xsmall};
  ${({ severity }) => {
    switch (severity) {
      case 'success':
        return css`
          background-color: ${colors.alerts.successTint3};
          border-left: ${spacing.tiny} solid ${colors.alerts.success};
        `;

      case 'error':
        return css`
          background-color: ${colors.alerts.errorTint3};
          border-left: ${spacing.tiny} solid ${colors.alerts.error};
        `;

      case 'warning':
        return css`
          background-color: ${colors.alerts.warningTint3};
          border-left: ${spacing.tiny} solid ${colors.alerts.warning};
        `;

      case 'info':
      default:
        return css`
          background-color: ${colors.alerts.infoTint3};
          border-left: ${spacing.tiny} solid ${colors.alerts.info};
        `;
    }
  }}
`;

export const LoadingWrapper = styled.div`
  display: flex;
  border: 1px solid ${colors.primary.grey};
  padding: 11px ${spacing.xmsmall};
`;

export const GroupLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  box-sizing: border-box;
  padding: ${spacing.medium} ${spacing.xsmall} ${spacing.xsmall} ${spacing.xsmall};
  border-bottom: 1px solid ${colors.monochrome.grey20};
  background-color: ${colors.base.white};
`;

import { type ReactNode } from 'react';
import { AppContainerWrapper, InnerAppContainerWrapper } from './styles';
import { AppContainerContextProvider } from './AppContainerContext';
import { useAppContainerContext } from './useAppContainerContext';

type AppContainerProps = {
  children: ReactNode;
  header: ReactNode;
  footer: ReactNode;
  backgroundColor?: string;
};
export const AppContainer = ({ header, footer, children, backgroundColor }: AppContainerProps) => {
  return (
    <AppContainerContextProvider initialBgColor={backgroundColor}>
      <InnerAppContainer header={header} footer={footer} backgroundColor={backgroundColor}>
        {children}
      </InnerAppContainer>
    </AppContainerContextProvider>
  );
};

export const InnerAppContainer = ({ header, footer, children }: AppContainerProps) => {
  const { backgroundColor } = useAppContainerContext();

  return (
    <AppContainerWrapper backgroundColor={backgroundColor}>
      <InnerAppContainerWrapper>
        <>{header}</>
        <>{children}</>
      </InnerAppContainerWrapper>
      {footer}
    </AppContainerWrapper>
  );
};

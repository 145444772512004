import { createContext } from 'react';
import { useDropdownCore } from '../hooks/useDropdownCore/useDropdownCore';
import { useDropdownSearch } from '../hooks/useDropdownSearch/useDropdownSearch';
import { useDropdownValues } from '../hooks/useDropdownValues/useDropdownValues';
import { useDropdownItems } from '../hooks/useDropdownItems/useDropdownItems';
import { type DropdownProviderProps } from '../types/dropdown';

export const DropdownProvider = (props: DropdownProviderProps) => {
  const { children } = props;

  const useItems = useDropdownItems({
    items: props.items,
  });

  const useValues = useDropdownValues({
    multiSelect: props.multiSelect,
    onChange: props.onChange,
    selectedValues: props.selectedValues,
    items: props.items,
    placeholder: props.placeholder,
    disabled: props.disabled || false,
    autoSelect: props.autoSelect,
  });

  const useSearch = useDropdownSearch({
    items: props.items,
    customSearch: props.customSearch,
  });

  const useDropdown = useDropdownCore({
    errorMessage: props.errorMessage,
    error: props.error,
  });

  return (
    <DropdownContexts.useDropdown.Provider value={useDropdown}>
      <DropdownContexts.useValues.Provider value={useValues}>
        <DropdownContexts.useSearch.Provider value={useSearch}>
          <DropdownContexts.useItems.Provider value={useItems}>
            <DropdownContexts.props.Provider value={props}>
              {children}
            </DropdownContexts.props.Provider>
          </DropdownContexts.useItems.Provider>
        </DropdownContexts.useSearch.Provider>
      </DropdownContexts.useValues.Provider>
    </DropdownContexts.useDropdown.Provider>
  );
};

export const DropdownContexts = {
  useItems: createContext<ReturnType<typeof useDropdownItems>>({
    items: [],
    setItems: console.log,
  }),
  useDropdown: createContext<ReturnType<typeof useDropdownCore>>({
    errorMessage: '',
    error: false,
    open: false,
    setError: console.log,
    setErrorMessage: console.log,
    toggleOpen: console.log,
    toggleOpenChild: console.log,
    closeAllChildren: console.log,
    dropdownRef: { current: null },
    isChildOpen: () => false,
  }),
  useSearch: createContext<ReturnType<typeof useDropdownSearch>>({
    searchActive: false,
    searchResults: [],
    onSearch: () => Promise.resolve(),
    resetSearch: console.log,
    searchText: '',
    hasSearchResults: false,
    searchResultsEmpty: false,
  }),
  useValues: createContext<ReturnType<typeof useDropdownValues>>({
    isAllSelected: () => false,
    toggleSelectAll: console.log,
    toggleItem: console.log,
    additionalSelectedAmount: 0,
    selectedItems: [],
    dropdownText: '',
    hasSelectedItems: false,
    isItemSelected: () => false,
    toggleValues: console.log,
  }),
  props: createContext<DropdownProviderProps>({
    label: '',
    multiSelect: false,
    children: null,
    disabled: false,
    items: [],
    placeholder: '',
    selectedValues: [],
    searchPlaceholder: 'search',
    onChange: console.log,
    required: false,
    icon: null,
    showInfoIcon: false,
    onInfoIconClick: console.log,
  }),
};

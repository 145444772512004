import * as Sentry from '@sentry/react';
import { FlagProvider, type IConfig, UnleashClient } from '@unleash/proxy-client-react';
import { useAuth0 } from '@auth0/auth0-react';
import { APP_ENV, UNLEASH_PROXY_CLIENT_TOKEN, UNLEASH_PROXY_URL } from '../utils/environment';
import { EventTrackingProvider } from '../hooks/useEventTracking/useEventTracking';
import { useTransactionId } from '../hooks/useTransactionId';

import { useProfileV2 } from '../Profile';
import { InitUnleash } from './initUnleash';

type GovernanceProviderProps = {
  children: React.ReactNode;
  sentryDsn: string;
  transactionId?: string;
};

const getAppName = () => {
  switch (APP_ENV) {
    case 'production':
      return 'prod';
    case 'development':
      return 'dev';
    default:
      return 'test';
  }
};

export const GovernanceProvider = ({ children, sentryDsn }: GovernanceProviderProps) => {
  const transactionId = useTransactionId();

  const { isAuthenticated } = useAuth0();
  const { basicProfile } = useProfileV2();

  const config: IConfig = {
    url: UNLEASH_PROXY_URL,
    clientKey: UNLEASH_PROXY_CLIENT_TOKEN,
    appName: getAppName(),
    refreshInterval: 30,
  };

  const client = new UnleashClient(config);
  const isSentryEnabled = APP_ENV === 'production' || APP_ENV === 'test';
  const getTracesSampleRate = () => {
    switch (APP_ENV) {
      case 'production':
        return 0.2;
      default:
        return 0.1;
    }
  };

  if (basicProfile) {
    Sentry.init({
      dsn: sentryDsn,
      integrations: [Sentry.browserTracingIntegration()],
      tracePropagationTargets: [
        'localhost',
        /^\//,
        'api.dev.stenarecycling.cloud',
        'api.test.stenarecycling.cloud',
        'api.prod.stenametall.cloud',
        'api.stenarecycling.com',
      ],
      ignoreErrors: ['TypeError: Load failed'],
      environment: APP_ENV,
      tracesSampleRate: getTracesSampleRate(),
      enabled: isSentryEnabled,
    });

    if (transactionId) {
      Sentry.getCurrentScope().setTag('transaction_id', transactionId);
    }
    Sentry.setUser({
      email: basicProfile.email,
      id: basicProfile.email,
    });
  }

  return (
    <FlagProvider startClient={false} unleashClient={client}>
      {isAuthenticated && <InitUnleash />}
      <EventTrackingProvider>{children}</EventTrackingProvider>
    </FlagProvider>
  );
};

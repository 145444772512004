import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../../themes/stena-recycling';
import {
  getInputTypeStyles,
  inputBorderStyle,
  inputFieldStyles,
} from '../../../helpers/inputHelpers';
import { type InputProps } from '.';

// InputBase
export const StyledInput = styled.input<Pick<InputProps, 'errorMessage' | 'originalType'>>`
  text-overflow: ellipsis;
  min-height: 48px;
  ${({ errorMessage }) => inputBorderStyle(!!errorMessage)}
  ${({ errorMessage }) => inputFieldStyles(!!errorMessage)}
  ${({ originalType }) => originalType && getInputTypeStyles(originalType)}
  &:focus {
    padding: ${spacing.xmsmall} calc(${spacing.xmsmall} - 1px);
  }
`;

// Textfield
export const TextfieldWrapper = styled.div<{ fullWidth?: boolean; mb?: string }>`
  position: relative;
  ${({ fullWidth }) => fullWidth && 'width: 100%'}
  flex-direction: column;
  ${({ mb }) => mb && `margin-bottom: ${mb}`}
`;

export const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextfieldError = styled.div`
  height: 22px;
  ${typography.body.medium}
  color: ${colors.alerts.error};
  margin-top: ${spacing.tiny};
`;

export const TooltipWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

// Password input
export const ShowPasswordWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: ${spacing.xxsmall};
`;

export const StyledInputWrapper = styled.div`
  position: relative;
  display: flex;
  height: ${spacing.mediumHigh};
`;

export const InputSuffix = styled.span<{ disabled?: boolean }>`
  color: ${colors.primary.grey};
  position: absolute;
  right: 0;
  border-radius: 0 ${spacing.tiny} ${spacing.tiny} 0;
  padding: calc(${spacing.xsmall} - 2px) calc(${spacing.xmsmall} - 2px);
  margin: 2px;
  background-color: ${colors.base.white};

  ${({ disabled }) => disabled && `background-color: ${colors.monochrome.grey10}`}
`;

/* eslint-disable @typescript-eslint/no-empty-function */
import { type ReactNode, useContext, useReducer, createContext, useMemo, useCallback } from 'react';
import {
  type SnackbarPayloadType,
  type SnackbarType,
  snackbarInitialState,
  SnackbarReducer,
} from '../SnackbarReducer';

export type SnackbarReduceType = {
  snackbarState: SnackbarType;
  showSnackbar: (param: SnackbarPayloadType) => void;
  resetSnackbar: () => void;
  hideSnackbar: () => void;
};
export const SnackbarContext = createContext<SnackbarReduceType>({
  snackbarState: snackbarInitialState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showSnackbar: (param: SnackbarPayloadType) => {},
  resetSnackbar: () => {},
  hideSnackbar: () => {},
});

type SnackbarProviderProps = {
  children?: ReactNode;
};

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [snackbarState, snackbarDispatch] = useReducer(SnackbarReducer, snackbarInitialState);

  const showSnackbar = useCallback(
    ({ title, description, severity, autoHide, hideDuration, small }: SnackbarPayloadType) => {
      snackbarDispatch({
        type: 'show',
        payload: { title, description, severity, autoHide, hideDuration, small },
      });
    },
    [snackbarDispatch],
  );

  const resetSnackbar = useCallback(() => {
    snackbarDispatch({ type: 'reset' });
  }, [snackbarDispatch]);

  const hideSnackbar = useCallback(() => {
    snackbarDispatch({ type: 'hide' });
  }, [snackbarDispatch]);

  const snackbarValue = useMemo(() => {
    return {
      snackbarState,
      showSnackbar,
      resetSnackbar,
      hideSnackbar,
    };
  }, [snackbarState, showSnackbar, resetSnackbar, hideSnackbar]);

  return <SnackbarContext.Provider value={snackbarValue}>{children}</SnackbarContext.Provider>;
};

export const useSnackbar = () => useContext(SnackbarContext);

import { User, spacing } from 'component-library';
import { useNavigate } from 'react-router-dom';
import { useOneSpa } from '../../hooks/useOneSpa';
import { oneSpaRoutePathsAdmin } from '../../routePaths/adminRoutePaths';
import { Link, LinkIconWrapper, LinkWrapper } from '../styles';
import { getOnboardingUrl } from '../../utils/environment';
import type { TextType } from '../../HeaderMenu';

const UserProfileLink = ({ text, closeMenu }: { text: TextType; closeMenu: () => void }) => {
  const isOneSpa = useOneSpa();

  const navigate = useNavigate();

  const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (isOneSpa) {
      e.preventDefault();
      navigate(oneSpaRoutePathsAdmin.PROFILE_DETAILS);
      closeMenu();
    }
  };

  return (
    <LinkWrapper>
      <Link
        onClick={(e) => {
          handleOnClick(e);
        }}
        href={`${getOnboardingUrl()}profile`}
        data-testid="user-profile-link"
      >
        <LinkIconWrapper>
          <User width={spacing.small} height={spacing.small} />
        </LinkIconWrapper>
        {text.userProfile}
      </Link>
    </LinkWrapper>
  );
};

export default UserProfileLink;

import { env } from '../../../../helpers/env';
import { type MenuService } from '../Services';

const { hostname } = window.location;

const PORTAL_RECYCLING_API_URL = env('PORTAL_RECYCLING_API_URL');

export const PORTAL_DISABLE_REACT_STRICT_MODE = env('PORTAL_DISABLE_REACT_STRICT_MODE', 'false');
export const UNLEASH_PROXY_CLIENT_TOKEN = env('PORTAL_UNLEASH_PROXY_CLIENT_TOKEN');
export const UNLEASH_PROXY_URL = env('PORTAL_UNLEASH_PROXY_URL');

export const getDomain = () => {
  if (APP_ENV === 'local') {
    return 'localhost';
  }

  if (APP_ENV === 'development') {
    return 'dev.stenarecycling.cloud';
  }

  if (APP_ENV === 'test') {
    return 'test.stenarecycling.cloud';
  }

  return 'stenarecycling.com';
};

export const getStartUrl = (isOneSpa?: boolean) => {
  if (APP_ENV === 'local') {
    if (isOneSpa) return 'http://localhost:4000/';

    return 'http://localhost:3020/';
  }

  if (APP_ENV === 'development') {
    return 'https://portal.dev.stenarecycling.cloud/';
  }

  if (APP_ENV === 'test') {
    return 'https://portal.test.stenarecycling.cloud/';
  }

  return 'https://portal.stenarecycling.com/';
};

export const getOnboardingUrl = () => {
  if (APP_ENV === 'local') {
    return 'http://localhost:3040/';
  }

  if (APP_ENV === 'development') {
    return 'https://portal.dev.stenarecycling.cloud/admin/';
  }

  if (APP_ENV === 'test') {
    return 'https://portal.test.stenarecycling.cloud/admin/';
  }

  return 'https://portal.stenarecycling.com/admin/';
};

export const getCo2ImpactUrl = () => {
  if (APP_ENV === 'local') {
    return 'http://localhost:3060';
  }

  if (APP_ENV === 'development') {
    return 'https://portal.dev.stenarecycling.cloud/co2impact';
  }

  if (APP_ENV === 'test') {
    return 'https://portal.test.stenarecycling.cloud/co2impact';
  }

  return 'https://portal.stenarecycling.com/co2impact';
};

export const getPWAUrl = () => {
  if (APP_ENV === 'local') {
    return 'http://localhost:3000';
  }

  if (APP_ENV === 'development') {
    return 'https://orderpickup.dev.stenarecycling.cloud';
  }

  if (APP_ENV === 'test') {
    return 'https://orderpickup.test.stenarecycling.cloud';
  }

  return 'https://orderpickup.stenarecycling.com';
};

export const APP_ENV = (() => {
  if (hostname.includes('test')) {
    return 'test';
  }
  if (hostname.includes('local')) {
    return 'local';
  }
  if (hostname.includes('dev')) {
    return 'development';
  }

  return 'production';
})();

export const getApiUrl = () => {
  return PORTAL_RECYCLING_API_URL;
};

export const getAuth0Config = () => {
  const test = {
    domain: 'auth.dev.stenarecycling.cloud',
    clientId: 'KmYbiYe5LJ63UIvSuH2boGPYFnGZakFi',
  };

  const prod = {
    domain: 'auth.stenarecycling.com',
    clientId: 'f4OwtyxQxxcU87msvpdS2f42KILUHJQC',
  };

  if (APP_ENV === 'local') {
    return test;
  }

  if (APP_ENV === 'development') {
    return test;
  }

  if (APP_ENV === 'test') {
    return test;
  }

  return prod;
};

export const getServiceUrl = (service: MenuService, oneSpa: boolean) => {
  if (oneSpa) {
    return OneSPAServiceUrls[service.name];
  }

  const linksArr = service.link.split(',');
  const dev = linksArr[0];
  const test = linksArr[1] || dev;
  const prod = linksArr[2] || test;

  if (APP_ENV === 'local') {
    return ServiceUrls[service.name] || dev;
  }

  if (APP_ENV === 'development') {
    return dev;
  }

  if (APP_ENV === 'test') {
    return test;
  }

  return prod;
};

export const ServiceUrls: Record<string, string> = {
  'services.order.pickup.name': 'http://localhost:3010',
  'services.follow.up.name': 'http://localhost:3050',
};

export const OneSPAServiceUrls: Record<string, string> = {
  'services.order.pickup.name': '/pickups',
  'services.follow.up.name': '/followup',
};

import { useState, useRef } from 'react';
import { Check, Chevron, colors, spacing } from 'component-library';
import ClickedOutside from './hooks/ClickedOutside';
import {
  LinkDropDownItem,
  LinkDropDownItemsList,
  LinkDropDownItemText,
  LinkDropDownWrapper,
  StyledLinkDropDown,
} from './styles';

export type LinkDropdownProps = {
  items: LinkDropdownItem[];
};

export type LinkDropdownItem = {
  href?: string;
  label: string;
  value: string;
  onClick?: () => void;
  active?: boolean;
};

export const LinkDropdown = ({ items }: LinkDropdownProps) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const showValueLabel = items.find((item) => item.active)?.label;
  const ref = useRef(null);

  ClickedOutside(ref, () => {
    setIsDropdownVisible(false);
  });

  return (
    <LinkDropDownWrapper ref={ref}>
      <StyledLinkDropDown
        onClick={() => {
          setIsDropdownVisible(!isDropdownVisible);
        }}
      >
        <LinkDropDownItemText>{showValueLabel}</LinkDropDownItemText>
        {isDropdownVisible && (
          <Chevron
            color={colors.base.black}
            width={spacing.small}
            height={spacing.small}
            rotation={180}
          />
        )}
        {!isDropdownVisible && (
          <Chevron color={colors.base.black} width={spacing.small} height={spacing.small} />
        )}
      </StyledLinkDropDown>

      {isDropdownVisible && (
        <LinkDropDownItemsList data-testid="link-dropdown-list">
          {items.map((item, index) => (
            <LinkDropDownItem
              key={index}
              active={item.active}
              onClick={() => {
                setIsDropdownVisible(false);
                if (item.onClick) {
                  item.onClick();
                } else if (item.href) {
                  window.location.href = item.href;
                }
              }}
              data-testid="list-item"
            >
              {item.label} {item.active && <Check width={spacing.small} height={spacing.small} />}
            </LinkDropDownItem>
          ))}
        </LinkDropDownItemsList>
      )}
    </LinkDropDownWrapper>
  );
};
